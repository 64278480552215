<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <div class="page-container">

        <md-app md-waterfall md-mode="fixed">
            <md-app-toolbar class="md-dense md-primary">
                <div class="md-toolbar-row">
                    <div class="md-toolbar-section-start"></div>

                    <div class="md-toolbar-section-end">
                        <md-menu md-direction="bottom-end">
                            <md-button :to="{name:'version'}">App Version</md-button>
                            <md-button :to="{name:'currencies'}">Currencies</md-button>
                            <md-button :to="{name:'bundles-list'}">Bundles</md-button>
                            <md-button :to="{name:'push-notifications'}">Push Notifications</md-button>
                            <md-button :to="{name:'family-stat'}">Family statistics</md-button>
                            <md-button :to="{name:'translations'}">Translations</md-button>
                            <md-button :to="{name:'languages'}">Languages</md-button>
                            <md-button :to="{name:'admin-tasks'}">Tasks</md-button>
                            <md-button :to="{name:'admin-rewards'}">Rewards</md-button>
                            <md-button :to="{name:'logout'}">Logout</md-button>

                        </md-menu>
                    </div>
                </div>

                <router-view name="toolbar"></router-view>

            </md-app-toolbar>

            <md-app-content>
                <router-view></router-view>
            </md-app-content>

        </md-app>


    </div>

</template>

<script>

    export default {
        data: () => ({
            menuVisible: false
        }),
        props: ["member_id"],
        created() {
            this.$store.dispatch('notifications/list')
            this.$store.dispatch('bundles/loadList')
        },
        computed: {

            // Get current member
            currentUser() {
                return this.$store.getters['family/getCurrentUser'];
            },
        },

        methods:
            {
                /**
                 * Go to page
                 * @param name
                 */
                goto(name) {
                    // Go to page
                    this.$router.push({name: name});

                    // Hide menu
                    this.menuVisible = false;
                }
            }
    }

</script>
